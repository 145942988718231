import React, { FC } from 'react';
import { PageLayout, PageHeading } from 'components';
import { graphql } from 'gatsby';
// import ReactMarkdown from 'react-markdown';

interface PageDetailType {
  data: {
    PageDetail: {
      title: string;
      content: string;
      description: string;
      slug: string;
    };
  };
}

const PageDetail: FC<PageDetailType> = ({ data }: any) => {
  const {
    PageDetail: { title, tagline, content, seo, file, files },
    strapiGlobal: { banner },
  } = data;
  const fileURL = file?.localFile?.publicURL;
  const fileName = file?.alternativeText ?? 'Download';
  // const { slug: CommunitySlug, title: CommunityTitle } =
  //   data.strapiCommunityPage;
  // const { slug: EntrySlug, title: EntryTitle } =
  //   data.strapiEntryRequirementPage;
  // const { slug: PrizeSlug } = data.strapiCompetitionPrizePage;

  // const conditionPage = data.allStrapiPage.edges.filter((filterItem: any) => {
  //   return (
  //     filterItem.node.slug === 'community/competition/about-the-competition'
  //   );
  // });
  return (
    <PageLayout
      title="Page"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      delaySec={0.8}
      isMainBannerNav={false}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle={title}
    >
      <div className="container">
        <PageHeading
          title={title}
          subtitle={tagline}
          description={content}
          level={1}
        />
        {/* {slug === 'community/competition/terms-and-conditions' ? (
          <div className="competition-page-header">
            <BreadCrumbs
              breadCrumb={[
                { title: 'Home', slug: '/' },
                { title: `${CommunityTitle}`, slug: `/${CommunitySlug}` },
                { title: 'Competition', slug: `/${CommunitySlug}/competition` },
              ]}
            />
            <h1>{title}</h1>
            <div className="competition-page-header-content">
              <ReactMarkdown className="md-html">{content}</ReactMarkdown>
            </div>
            <div className="competition-button">
              <div className="competition-button-wrapper">
                <Link
                  to={`/${CommunitySlug}/competition`}
                  className="competition-button-link"
                >
                  <span>Enter Now</span>
                </Link>
              </div>
              <div className="competition-button-wrapper">
                <Link
                  to={`/${CommunitySlug}/competition/${PrizeSlug}`}
                  className="competition-button-link"
                >
                  <span>View Prizes</span>
                </Link>
              </div>
              <div className="competition-button-wrapper">
                <Link
                  to={`/${CommunitySlug}/competition/${EntrySlug}`}
                  className="competition-button-link"
                >
                  <span>{EntryTitle}</span>
                </Link>
              </div>
              <div className="competition-button-wrapper">
                <Link
                  to={`/${conditionPage[0].node.slug}`}
                  className="competition-button-link"
                >
                  <span>{conditionPage[0].node.title}</span>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <>
            {slug === 'community/competition/about-the-competition' ? (
              <>
                <div className="competition-page-header">
                  <BreadCrumbs
                    breadCrumb={[
                      { title: 'Home', slug: '/' },
                      { title: `${CommunityTitle}`, slug: `/${CommunitySlug}` },
                      {
                        title: 'Competition',
                        slug: `/${CommunitySlug}/competition`,
                      },
                    ]}
                  />
                  <h1>{title}</h1>
                  <div className="competition-page-header-content">
                    <ReactMarkdown className="md-html">{content}</ReactMarkdown>
                  </div>
                  <div className="competition-button">
                    <div className="competition-button-wrapper">
                      <Link
                        to={`/${CommunitySlug}/competition`}
                        className="competition-button-link"
                      >
                        <span>Enter Now</span>
                      </Link>
                    </div>
                    <div className="competition-button-wrapper">
                      <Link
                        to={`/${CommunitySlug}/competition/${PrizeSlug}`}
                        className="competition-button-link"
                      >
                        <span>View Prizes</span>
                      </Link>
                    </div>
                    <div className="competition-button-wrapper">
                      <Link
                        to={`/${CommunitySlug}/competition/${EntrySlug}`}
                        className="competition-button-link"
                      >
                        <span>{EntryTitle}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {slug === 'community/competition/thank-you' ? (
                  <div className="competition-thankyou-page">
                    <PageHeading
                      title={title}
                      subtitle={tagline}
                      description={content}
                      level={1}
                    />
                    <div className="competition-button">
                      <div className="competition-button-wrapper">
                        <Link
                          to={`/${CommunitySlug}/competition`}
                          className="competition-button-link"
                        >
                          <span>View Entries</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <PageHeading
                    title={title}
                    subtitle={tagline}
                    description={content}
                    level={1}
                  />
                )}
              </>
            )}
          </>
        )} */}
        {fileURL && (
          <div className="container">
            <a
              className="file-link"
              href={fileURL}
              target="_blank"
              rel="noreferrer"
            >
              {fileName}
            </a>
          </div>
        )}
        {files && files.length > 0 && (
          <div className="container">
            <ul className="filesList">
              {files.map((listFile: any) => (
                <li key={listFile.title}>
                  <a
                    className="file-link"
                    href={listFile.file?.localFile?.publicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {listFile.title
                      ? listFile.title
                      : listFile?.file?.localFile?.publicURL?.alternativeText}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default PageDetail;
export const query = graphql`
  query PageDetailsQuery($slug: String) {
    PageDetail: strapiPage(slug: { eq: $slug }) {
      title
      content
      tagline
      slug
      file {
        alternativeText
        localFile {
          publicURL
        }
      }
      files {
        title
        file {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    strapiGlobal {
      banner: defaultBanner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiPage {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

// strapiCommunityPage {
//   title
//   slug
// }
// strapiEntryRequirementPage {
//   title
//   slug
// }
// strapiCompetitionPrizePage {
//   title
//   slug
// }
